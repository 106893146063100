<template>
  <div class="project-refund-reject">
    <fd-form class="card" @submit="submitForm">
      <modal-body class="p-3">
        <h2 class="mb-2">Reject Refund</h2>
        <p class="mb-4">Please write a reason for this rejection of refund.</p>
        <fd-textarea
          v-model="form.description"
          class="col-12 mb-2"
          label="Description"
          name="approveRefundRemark"
        >
        </fd-textarea>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <fd-button class="mr-1" @click="$emit('cancel')">Cancel</fd-button>
        <fd-button
          type="submit"
          loadingEnabled
          :isLoading="isFormLoading"
          :disabled="isFormLoading"
          class="danger"
          >Reject</fd-button
        >
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { ProjectRefundModel as refundModel } from "@/models";
export default {
  components: {},
  mixins: [],
  props: {
    refundId: {
      type: [String, Number],
      required: true
    }
  },
  data: function () {
    return {
      isFormLoading: false,
      form: {
        description: ""
      },

      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit(
        "submit",
        this.refundId,
        refundModel.putRejectPayload(this.form)
      );
    }
  }
};
</script>

<style lang="scss">
.project-refund-reject {
  @media #{$breakpoint-up-sm} {
    min-width: 500px;
  }
}
</style>
